:root {
    --scrollbar-invert-color1: #b0b0b0;
    --scrollbar-invert-color2: #d5d5d5;
}

body.inverted .vbox_left, .inverted .vbox_right {
    background-color: #cccccc;
}

body.inverted .floater {
    background-color: #cccccc;
}
 
body.inverted .nexusDialog > .nexusDialogHeader {
    background-color: #999;
}

body.inverted .nexusDialog  {
    border-color: #999;
}

body.inverted .inputLine {
    background-color: #c0c0c0;
    border: 1px #aaa solid;
}

body.inverted {
    scrollbar-color: var(--scrollbar-invert-color1) var(--scrollbar-invert-color2);
    scrollbar-width: thin;
}

body.inverted ::-webkit-scrollbar {
    width: 10px;
    background-color: var(--scrollbar-invert-color2);
    border-radius: 4px;
}

body.inverted ::-webkit-scrollbar-thumb {
    background: var(--scrollbar-invert-color1);
    border-radius: 4px;
}

body.inverted #footer {
    border-top: 1px solid #235657;
    background-color: #e0e0e0;
}

body.inverted #footer div.separator {
   border-right: 0px solid #235657;
}

body.inverted #footer > div#status_vars > div {
    color: #235657;
    text-shadow: 0 0 1px #778899;
}

body.inverted #footer .statusMessages, body.inverted #footer .statusNews, body.inverted #footer #status_icons #icon_fullstop:not(.off) {
    color: #00a000;
}

body.inverted .highlightText {
    color: black !important;
}

body.inverted .rdesc {
    color: #555555;
}

body.inverted #map_container {
    background-color: #e0e0e0;
}

body.inverted .flexlayout__tab {
    background-color: #cccccc !important;
}

body.inverted .flexlayout__tabset_tabbar_outer {
    background-color: #cccccc !important;
}

body.inverted .flexlayout__layout {
  --color-text: black;
  --color-background: white;
  --color-base: white;
  --color-1: #f7f7f7;
  --color-2: #e6e6e6;
  --color-3: #d9d9d9;
  --color-4: #cccccc;
  --color-5: #bfbfbf;
  --color-6: #b3b3b3;
  --color-drag1: #5f86c4;
  --color-drag2: #77a677;
  --color-drag1-background: rgba(95, 134, 196, 0.1);
  --color-drag2-background: rgba(119, 166, 119, 0.075);
}
