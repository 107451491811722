.creation_dialog * {
    font-family: 'Montserrat';
}

.creation_dialog .creation_dlg_title {
    display: inline-block;
    width: 200px;
    height: 43px;
    position: relative;
    color: #888;
    text-align: center;
    font-size: 16px;
    padding-top: 8px;
    box-sizing: border-box;
    margin-left: -25px;
    transform: translateX(25px);
    background-image: url("../../public/images/creation/breadcrumb-off.png");
}

.creation_dialog .MuiDialogContent-root {
    padding: 0px;
}

.creation_dialog .creation_dlg_active_title {
    color: #F7C854;
}

.creation_dialog #creation_content {
    height: 100%;
}

.creation_dialog .MuiButton-contained {
    border-style: solid;
    border-color: #aaaaaa;
}

.creation_dialog .MuiListItemButton-root.Mui-disabled {
    pointer-events: all !important;
}

.creation_dialog .creation_choice_text {
    height:16px;
    color: #a5a7e1;
    text-align: center;
    font-size: .9em;
}

.creation_dialog .creation_list {
    overflow-x: auto;
    overflow-y: scroll;
}

.creation_dialog .creation_list_header {
    text-align: center;
    font-family: "Cinzel Decorative";
    font-size: 36px;
    font-weight: 700;
    padding-top: 10px;
    background-color: rgba(0,0,0,0.3);
}

.creation_dialog .creation_list_subheader {
    text-align: center;
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 500;
    padding: 0 20% 15px 20%;

    background-color: rgba(0,0,0,0.3);
}

.creation_dialog .creation_list_group {
    padding-top: 15px;
    padding-bottom: 3px;
    font-size: 1.3em;
    text-align: center;
}

.creation_dialog .creation_list_entries {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
    margin-left: 20%;
    margin-right: 20%;
    margin-top: 20px;
    justify-content: center;
}

.creation_dialog .list_item {
    text-align: center;
    width: 180px;
    cursor: pointer;
}

.creation_dialog .item_disabled {
    cursor: default !important;
}

.creation_dialog .list_item .list_item_image {
    border-radius: 8px;
    border: 3px solid #646464;
    display: block;
    margin: auto;
    width: 150px;
}

.creation_dialog .list_item:not(.item_disabled):hover .list_item_image {
    border-color: #cccccc;
}

.creation_dialog .list_item .list_item_text {
    background-color: black;
    border-radius: 8px;
    border: 2px solid #cccccc;
    margin: auto;
    width: 170px;
    margin-top: 10px;
    font-size: 0.9em;
}

.creation_dialog .list_item:not(.item_disabled):hover .list_item_text {
    background-color: white;
    color: black;
}

.creation_dialog .list_item.item_disabled .list_item_text {
    border-color: #646464;
    color: #646464;
}

.creation_dialog .creation_list_games {
    width: 0px;
    display: flex;
    flex-direction: column;
    margin-right: 56px;
    margin-bottom: 5px;
}

.creation_dialog .creation_list_games_inner {
    padding-left: 10px;
    padding-top: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
}

.creation_dialog .creation_right .creation_subtitle {
    color: #a5a7e1;
    text-transform: uppercase;
    text-align: center;
    font-size: 1.6em;
    background-color: rgba(0, 0, 0, 0.4);
    height: 32px;
}

.creation_dialog .creation_list_main {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: calc(100% - 32px);
}

.creation_dialog .creation_loading {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.creation_dialog .creation_loading > div {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.creation_dialog .creation_details {
    flex-grow: 1;
    padding: 5px;
    overflow: scroll;
}

.creation_dialog .creation_stage_title {
    text-align: center;
    text-transform: uppercase;
}

.creation_dialog .creation_details button {
    width: 100%;
    display: block;
}

.creation_dialog .creation_list_center {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.creation_dialog .creation_details_title {
    font-family: 'CinzelDecorative';
    font-size: 2em;
    font-weight: bold;
    padding: 5px 0px 5px 0px;
    color: #ffffff;
    text-align: left;
    margin-bottom: 5px;
}

.creation_dialog .creation_details_subtitle {
    font-family: 'Montserrat';
    font-size: 1.5em;
    font-style: italic;
    color: #ffffff;
    padding: 0px 0px 5px 0px;
    text-align: left;
    margin-top: -5px;
    margin-bottom: 0px;
}

.creation_dialog .creation_details_main {
    display: flex;
    width: calc(100% - 200px);
    background-color: #000000;
    border-radius: 10px;
    border: #cccccc solid 1px;
    height: 90%;
    display: flex;
    flex-direction: column;
    padding: 0px 10px 5px 10px;
    margin-left: 100px;
    margin-right: 100px;
    margin-top: 20px;
    position: relative;
}

.creation_dialog .creation_details_main .close_button {
    position: absolute;
    right: 5px;
    top: 5px;
    padding: 0px;
    font-size: 30px;
    cursor: pointer;
    display: block;
    line-height: 30px;
    color: white;
    background-color: black;
    border-radius: 3px;
    padding: 3px;
}

.creation_dialog .creation_details_main .close_button:hover {
    color: red;
}

.creation_dialog .next_button {
    color: white;
    padding: 18px 48px;
    font-family: 'Montserrat';
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
    display: inline-block;
    border-radius: 6px;
}

.creation_dialog .next_button:not(.item_disabled):hover {
    background-color: white;
    color: black;
}

.creation_dialog .creation_details_center {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    height: 100%;
}

.creation_dialog .creation_details_center .creation_details_text {
    height: 100%;
    flex-grow: 1;
}

.creation_dialog .creation_details_center .creation_details_content {
    overflow-y: scroll;
}

.creation_dialog .creation_details_image {
    margin-top: 10px;
    display: block;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    min-height: 100px;
    max-height: calc(100% - 10px);
    position: relative;
    border: px;
}

.creation_dialog .creation_details_image_wrapper {
    width: 33%;
    text-align: center;
}

.creation_dialog .creation_details_image_wrapper img {
    border-radius: 5px;
    margin-bottom: 10px;
}
.creation_dialog .restriction_warning {
    color: red;
}

.creation_dialog .creation_details_text {
    padding-top: 5px;
    padding: 10px 15px;
    box-sizing: border-box;
    color: white;
    min-width: 250px;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.creation_dialog .creation_name_hint {
    font-size: 0.8em;
    font-style: italic;
    margin-top: 3px;
}

.creation_dialog .creation_right {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.creation_dialog .creation_content {
    display: flex;
    flex-direction: row;
    height: 100%;
    position: relative;
}

.creation_dialog .creation_name_main {
    height: 100%;
    flex: 1;
    margin: 0 20%;
    text-align: left;
    background-color: rgba(0,0,0,0.6);
    padding: 0 10px;
}

.creation_dialog .creation_name_main .MuiInputBase-root {
    border-radius: 4px;
    border: 1px solid #FFF;
    background: #747474;
    color: white;
    font-family: Montserrat;
    font-size: 16px;
}

.creation_dialog .creation_details_header {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: -10px;
    padding-left: 10px;
}

.creation_dialog .creation_details_header_texts {
    flex: 1;
    min-width: 40%;
}

.creation_dialog .creation_details_buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 15px;
    padding-top: 20px;
    padding-right: 20px;
    align-items: center;
    padding-bottom: 15px;
}

.creation_dialog .creation_name_wrapper {
    overflow: scroll;
}

.creation_dialog .creation_age_slider {
    width: 250px;
    margin-left: 25px;
    margin-top: 5px;
}
.creation_dialog .creation_age_input {
    width: 70px;
    padding-left: 5px;
}

.creation_dialog .MuiFormLabel-root:not(.MuiInputLabel-root) {
    padding-top: 8px;
    padding-bottom: 0px;
}

.creation_dialog .creation_name_right {
    margin-left: 100px;
}

.creation_dialog .MuiFormGroup-root {
    padding-top: 10px;
}

.creation_dialog .MuiFormControl-root {
    min-width: 600px;
}

.creation_dialog .creation_list .MuiListItem-root {
    padding-top: 2px;
    padding-bottom: 2px;
}

.creation_dialog .creation_list .Mui-selected {
    border-left: 5px white solid;
    padding-left: 11px;
}

.creation_dialog .MuiListItemText-primary {
    color: white;
    text-transform: uppercase;
    font-size: 1.4em;
}

.creation_dialog .MuiListItemText-secondary {
    color: #a5a7e1;
    font-style: italic;
}

.creation_dialog .MuiButton-label {
    font-size: 1.2em;
}

.creation_dialog .creation_steps {
    display: flex;
    justify-content: center;
    /*align-items: center;*/
    padding: 24px 20px;
    gap: 20px;
    background-color: rgba(0,0,0,0.5);
}

.creation_dialog .creation_steps .creation_step {
    display: block;
    flex: 1;
    color: white;
    font-family: Montserrat;
    font-size: 1.4vw;
    font-weight: 700;
    text-align: center;
    white-space: nowrap;
}

.intro_dialog .intro_games {
    display: flex;
    flex-direction: row;
    background-color: #333;
    border-radius: 12px;
    padding: 15px;
    column-gap: 20px;
    width: 1000px;
    margin: auto;
    overflow-y: scroll;
    
}

.intro_dialog .intro_games .intro_game {
    flex: 1;
    background-color: white;
    border-radius: 12px;
    padding: 15px;
    color: #333;
    cursor: pointer;
    text-align: left;
    display: flex;
    flex-direction: column;
}

.intro_dialog .intro_games .intro_game_button {
    min-width: 150px;
    font-weight: bold;
    color: white;
}

.creation_dialog .desc_heading {
    font-size: 1.2em;
    font-style: italic;
    padding-bottom: 5px;
}

.creation_dialog .creationListImage {
    position: relative;
    height: 100%;
}

.creation_dialog .creationListImage .imageShiftLeft, .creation_dialog .creationListImage .imageShiftRight {
    display: none;
    position: absolute;
    height: 100%;
    width: 20%;
    top: 0;
    background-color: rgba(0, 0, 0, 0.6);
    color: #aaaaaa;
    font-size: 2em;
    justify-content: center;
    flex-direction: column;
}

.creation_dialog .creationListImage:hover .imageShiftLeft, .creation_dialog .creationListImage:hover .imageShiftRight {
    display: flex;
    cursor: pointer;
}

.creation_dialog .creationListImage .imageShiftLeft {
    left: 0;
}

.creation_dialog .creationListImage .imageShiftRight {
    right: 0;
}

.creation_dialog .MuiFormHelperText-root.Mui-error {
    padding-left: 4px;
}

.creation_dialog .nameform_bottom_elements {
    display:flex;
    flex-direction: row;
    width: 600px;
    align-items: end;
}

/** MOBILE STYLES */

@media (hover: none) { 
    .creation_dialog .MuiFormControl-root {
        width: 100%;
        min-width: 100%
    }
    .creation_dialog .MuiButtonBase-root {
        min-width: 0px;
    }
    .creation_dialog .nameform_bottom_elements {
        flex-direction: column;
        align-items: start;
    }
    .creation_dialog .nameform_bottom_elements .MuiButton-contained {
        width: 240px !important;
    }
}
