:root {
    --scrollbar-color1: #3c3c3c;
    --scrollbar-color2: #1b1b1b;
}

@font-face{
    font-family: 'LiberationMono';
    src: url('../../public/fonts/LiberationMono.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}



@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../../public/fonts/Montserrat-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Arimo';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../../public/fonts/Arimo-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'EBGaramond';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../../public/fonts/EBGaramond-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../../public/fonts/Roboto-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'RobotoMono';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../../public/fonts/RobotoMono-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'NunitoSans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../../public/fonts/NunitoSans10pt-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'OpenSans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../../public/fonts/OpenSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Playfair';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../../public/fonts/Playfair144pt-Regular.ttf') format('truetype');
}


@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../../public/fonts/Montserrat-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'CinzelDecorative';
    src: url('../../public/fonts/CinzelDecorative-Regular.ttf');
    src: url('../../public/fonts/CinzelDecorative-Regular.ttf') format('truetype');
}

a {
  color: inherit;
}

a:visited {  /* let's not have visited links */
  color: inherit;
}

div.h100 {
    height: 100%;
}

.vbox {
    flex-grow: 0;
    height: inherit;
    box-sizing: border-box;
}

.vbox_center {
}

.vbox_left, .vbox_right {
    background-color: #333333;
}

.box_main {
    background-color: #333333;
}

.flexlayout__tab {
    background-color: #333333 !important;
    color: inherit !important;
}

.flexlayout__tab > .MuiBox-root {
    padding-left: 2px;
}

.flexlayout__tabset_tabbar_outer {
    background-color: #222222 !important;
}

.flexlayout__tabset_tabbar_outer_top {
    border-bottom: 0px !important;
}

.flexlayout__tab_button_content {
    display: none !important;
}

.flexlayout__tabset-selected {
    background-image: none !important;
}

.floater {
    background-color: #333333;
}

body {
    scrollbar-color: var(--scrollbar-color1) var(--scrollbar-color2);
    scrollbar-width: thin;
}

.mxp_send {
    color: inherit;
}

::-webkit-scrollbar {
    width: 10px;
    background-color: var(--scrollbar-color2);
    border-radius: 0px;
}

::-webkit-scrollbar-thumb {
    background: var(--scrollbar-color1);
    border-radius: 4px;
}

.simpleTab {
    overflow: auto;
    height: 100%;
    box-sizing: border-box;
    padding: 8px;
}

.simpleTab .MuiBox-root {
    height: 100%;
    box-sizing: border-box;
    padding: 0px;
}

.inputLine {
    flex-grow: 1;
    height: calc(100% - 100px);
    background-color: #000000;
    border: 1px #555 solid;
    border-radius: 8px;
    margin-top: 5px;
    margin-bottom: 5px;
}


div[role=tooltip] {
    z-index: 5200;
}

.output {
    word-wrap: break-word;
    white-space: pre-wrap;
}

.output_scrollback {
    border-top: 2px #777 solid;
}

div.textline {
    color: #aaaaaa;
}

/** This makes sure that empty lines don't have a zero height. */
div.textline:after {
    content: '\200B';
    visibility: hidden;
}

#buttons .MuiButton-outlined {
    border: 1px solid #aaaaaa;
    height: 100%;
    white-space: nowrap;
    min-width: 10px;
}

#buttons .MuiButton-outlined:hover {
    color: black;
}

.highlightText {
    color: white !important;
}

.MuiButton-containedSecondary:not(:hover) {
    color: white !important;
}

/** FONTS */
.mono {font-family: 'LiberationMono';}


/** LOGIN SCREEN */
#btn_new_character {
    width: 235px;
    height: 75px;
    background-image: url("../../public/images/login/button-start-off.png");
}

#btn_new_character:hover {
    background-image: url("../../public/images/login/button-start-on.png");
}


/** STATUSBAR */
#footer {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    border-top: 0px solid #B3B6B7;
    border-bottom: 0px;
    font-size: 1.2em;
    z-index: 1;
    position: relative;
    display: flex;
    flex-direction: row;
    background-color: #121212;
}

#footer div.separator {
   margin: 7px;
   height: 24px;
   background-position: left center;
   background-repeat: no-repeat;
   border-right: 0px solid #B3B6B7;
}

#status-daytime.daytime-day {
    background-image: url("../../public/images/bottom/sun_light_bg_small.png");
    background-size: contain;
}

#footer #status-daytime.daytime-night {
    background-image: url("../../public/images/bottom/moon_light_bg_small.png");
    background-size: contain;
}

#footer #status-daytime.daytime-day div.daynight-icon {
    background-image: url("../../public/images/bottom/sun.png");
    background-size: contain;
}

#footer #status-daytime.daytime-night div.daynight-icon {
    background-image: url("../../public/images/bottom/moon.png");
    background-size: contain;
}

#footer #status-daytime {
    background-repeat: no-repeat;
    margin-top: auto;
    margin-bottom: auto;
}

#footer #daynight_indicator {
    padding-top: 0 !important;
}

#status-daytime div.daynight-wrap {
    border: 0px;
    position: relative;
    overflow: hidden;
}

#status-daytime div.daynight-icon {
    width: 33.76%;
    height: 81.25%;
    top: 1px;
    position: absolute;
}

#footer #status_vars {
    text-align: left;
    font-size: 1em;
    overflow: hidden;
    white-space: nowrap;
    margin-top: auto;
    margin-bottom: auto;
}

#footer #extra_icons {
    text-align: left;
    font-size: 1em;
    margin-top: auto;
    margin-bottom: auto;
    padding-left: 10px;
    overflow: hidden;
    white-space: nowrap;
}

#footer #extra_icons > *{
    padding-right: 10px;
}

#footer #left_icons {
    text-align: left;
    font-size: 25px;
    padding-left: 10px;
    overflow: hidden;
    white-space: nowrap;
}

#footer > div#status_vars > div {
    min-width: 120px;
    max-width: 200px;
    line-height: 22px;
    text-align: center;
    vertical-align: middle;
    color: #B3B6B7;
    text-shadow: 0 0 1px #778899;
    margin-left: 5px;
    margin-top: -2px;
}

#footer #status_icons {
    padding-top: 2px;
}

#footer #status_icons > * {
    margin: 3px 4px;
    text-align: center;
    cursor: pointer;
}

#footer #status_icons .off {
    opacity: 0.50;
}

#footer #status_icons #icon_fullstop:not(.off) {
    color: rgb(0, 255, 0);
}

#footer #status_icons #icon_logging:not(.off) {
    color: rgb(0, 255, 0);
}

#footer #status_icons #icon_fullstop.off {
    color: rgb(255, 0, 0);
    opacity: 1;
}

#footer #help {
    margin-top: 1px !important;
    margin-left: 1px !important;
}

#footer #vote {
    margin-top: 1px !important;
    margin-left: 3px !important;
}

#footer .statusMessages, #footer .statusNews {
    color: #80ff80;
}


/** BOTTOM VIEW */
.bottom_view {
    background-color: #1b1b1b;
    display: grid;
    padding: 5px 0px;
}

.bottom_view > * {
    display: flex;
    flex-direction: column;
    justify-content: center;
}


/** BALANCES */
#component_balances {
}

#component_balances > .balance {
    background-repeat: no-repeat;
}

#component_balances > .balance.lost {
    background-image: none;
}
#component_balances > .bal {
}
#component_balances > .eq {
}

#component_balances > .balance {
    padding: 0;
    display: block;
    border: 0;
    color: #eaac15;
}

/** COMPASS */
.movement_buttons {
    position: relative;
}

.movement_buttons > img.movement_button {
    height: 25%;
    width: 20%;
    display: block;
    position: absolute;
    margin: 0;
    padding: 0;
}

.movement_buttons > img.movement_button.movement_enabled {
    cursor: pointer;
}

.movement_buttons > img.movement_button.movement_button_l { height: 50%; left: 40%; top: 25%; }
.movement_buttons > img.movement_button.movement_button_toggle { right: 0; top: 0; }

.movement_buttons > img.movement_button.movement_button_nw { left: 20%; top: 12.5%; }
.movement_buttons > img.movement_button.movement_button_ne { left: 60%; top: 12.5%; }
.movement_buttons > img.movement_button.movement_button_sw { left: 20%; top: 62.5%; }
.movement_buttons > img.movement_button.movement_button_se { left: 60%; top: 62.5%; }
.movement_buttons > img.movement_button.movement_button_n  { left: 40%; top: 0%; }
.movement_buttons > img.movement_button.movement_button_e  { left: 60%; top: 37.5%; }
.movement_buttons > img.movement_button.movement_button_s  { left: 40%; top: 75%; }
.movement_buttons > img.movement_button.movement_button_w  { left: 20%; top: 37.5%; }
.movement_buttons > img.movement_button.movement_button_in  { left: 0%; top: 25%; }
.movement_buttons > img.movement_button.movement_button_out  { left: 0%; top: 50%; }
.movement_buttons > img.movement_button.movement_button_u  { left: 80%; top: 25%; }
.movement_buttons > img.movement_button.movement_button_d  { left: 80%; top: 50%; }


/** AFFDEF */

#tab_affdef_main {
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    font-size: 0.85em;
}

#tab_affdef_main #tab_affdef_top {
    clear: both;
}

#tab_affdef_main #tab_affdef_top_left {
    margin-top: -8px;
}

#tab_affdef_main .tab_affdef_heading {
    font-size: 1.2em;
    color: #aaa;
    margin: 1px 0 1px 0;
    clear: both;
    text-align: center;
}

#tab_affdef_main .tab_affdef_heading > a {
    display: inline-block;
    padding: 5px 20px;
    margin: 10px;
    border-radius: 4px;
    border: 1px solid #aaaaaa;
    background-color: rgba(92, 98, 101, 0.75);
    cursor: pointer;
    color: white;
}

#tab_affdef_main .tab_affdef_heading > a:hover {
    filter: brightness(120%);
}

#tab_affdef_main .tab_affdef_heading > a.tab_affdef_heading_sel {
    background-color: rgba(0, 179, 0, 0.75);
}

#tab_affdef_main #tab_affdef_lists {
    clear: both;
    line-height: 1.3em;
    padding: 2px;
    margin-bottom: 3px;
    position: relative;
}

#tab_affdef_main #tab_affdef_lists > div {
    position: absolute;
    top: 0;
    left: 0;
}

#tab_affdef_main #tab_affdef_defs {
    margin: auto;
    max-width: 60%;
    text-align: center;
    font-size: 1.8em;
}

#tab_affdef_main #tab_affdef_defs > * {
    margin: 5px 3px;
}

#tab_affdef_main #tab_affdef_info {
    font-size: 1.1em;
    text-align: left;
    padding-left: 10px;
    font-family: Arimo, Verdana, Arial;
    margin-bottom: 10px;
}

#tab_affdef_main #tab_affdef_stats {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

#tab_affdef_main #tab_affdef_info #tab_affdef_charname {
    font-size: 1.5em;
    margin-top: 5px;
    margin-bottom: 5px;
    font-weight: bold;
}

#tab_affdef_main #tab_affdef_top > div {
    float: left;
    position: relative;
}

#tab_affdef_main #tab_affdef_body > img:not(#affdef_body_shield) {
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    z-index: 1;
}

#tab_affdef_main .affdef_off {
    opacity: 0.15;
}

.rdesc {
    color: #aaaaaa;
    font-size: 0.85em;
}


/** text window */

.nexusDialog.textWindow {
    display: flex;
    flex-direction: column;
    min-width: 700px;
    min-height: 500px;
    border: solid #536878 5px;
    background-color: #222;
    width: 60vw;
    height: 60vh;
    position: relative;
}

.nexusDialog.textWindow > .nexusDialogHeader {
    width: 100%;
    height: 32px;
    text-align: center;
    font-size: 18px;
    cursor: pointer;
    padding-top: 4px;
    background-color: #536878;
    font-family: 'Montserrat';
    text-transform: uppercase;
}

.nexusDialog.textWindow > .nexusDialogHeader .MuiIconButton-root {
    color: #475967;
}

.nexusDialog.textWindow > .content {
    flex-grow: 1;
}

/** DIALOGS */

.MuiDialogContent-root {
    padding-top: 10px !important;
}

.MuiModal-root .MuiDialogContent-root {
    padding: 0 !important;
}

.MuiModal-root .MuiDialogTitle-root {
    color: black;
    background-color: #d8d8d8;
    
}

.alert_content, .input_content, .confirm_content, .paddedContent {
    padding: 15px;
}

.alert_content .MuiFormControl-root, .input_content .MuiFormControl-root, .confirm_content .MuiFormControl-root, .paddedContent .MuiFormControl-root {
    margin-top: 5px;
}

.nexusDialog {
    display: flex;
    flex-direction: column;
    min-width: 500px;
    min-height: 300px;
    border: solid #536878 5px;
    border-radius: 6px;
}

.nexusDialog > .nexusDialogHeader {
    width: 100%;
    /*height: 32px;*/
    text-align: center;
    font-size: 1.4em;
    cursor: pointer;
    padding-top: 4px;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: #707070;
    background-color: #536878;
    font-family: 'Montserrat';
    text-transform: uppercase;
}

.nexusDialog > .nexusDialogContent {
    flex-grow: 1;
    overflow: auto;
}

.settingsDialog .MuiTextField-root {
    margin-top: 5px;
}

.nexusDialog .dialogPanel {
    padding: 5px;
    overflow-y: auto;
    flex-grow: 1;
}

.formSection .MuiButton-root {
    margin: 3px;
}

.formSectionHeader {
    font-size: 1.5em;
    margin-top: 1.5em;
    margin-bottom: 5px;
}

.rc-tree .rc-tree-treenode .draggable {
    color: inherit !important;
}

.rc-tree .filter-node {
    display: none;
}

.MuiButton-root.greenButton {
    background-color: #013601;
    color: 'white';
    border-color: #016401;
    border-width: 1px;
    border-style: solid;
}

.MuiButton-root.lightGreen.greenButton {
    background-color: #018601;
}    

.charactersButton {
    background-color: #013601;
    color: 'white';
    border-color: #016401;
    border-width: 1px;
    border-style: solid;
}

.charactersButton:not(.item_disabled):hover {
    background-color: white;
    color: black;
}

.charactersButton.achaeaButton {
    background-color: #30b4be;
    border-color: #25a4ae;
}
.charactersButton.aetoliaButton {
    background-color: #be3030;
    border-color: #ae2828;
}
.charactersButton.imperianButton {
    background-color: #30be4d;
    border-color: #28ae40;
}
.charactersButton.lusterniaButton {
    background-color: #8a30be;
    border-color: #7c28ae;
}
.charactersButton.starmournButton {
    background-color: #4682B4;
    border-color: #787878;
}
.achaeaColor {
    color: #30b4be !important;    
}
.aetoliaColor {
    color: #be3030 !important;
}
.imperianColor {
    color: #30be4d !important;
}
.lusterniaColor {
    color: #8a30be !important;    
}
.starmournColor {
    color: #4682B4 !important;
}

.achaeaHeaderBG {
    background-color: rgba(48, 180, 190, 0.2);
}
.aetoliaHeaderBG {
    background-color: rgba(190, 48, 48, 0.2);
}
.imperianHeaderBG {
    background-color: rgba(48, 190, 78, 0.2);
}
.lusterniaHeaderBG {
    background-color: rgba(138, 48, 190, 0.2);
}
.starmournHeaderBG {
    background-color: rgba(70, 130, 180, 0.2);
}

.character_list .gameBlurbs {
    width: 50%;
    margin: auto;
}

.gameBlurb1 {
    color: white;
    font-weight: bold;
    margin-top: 30px;
}

.gameBlurb2 {
    color: white;
}

.gameBlurb3 {
    font-style: italic;
    margin-top: 50px;
}

.achaeaBlurb3 {
    color: #30b4be;
}
.aetoliaBlurb3 {
    color: #be3030;
}
.imperianBlurb3 {
    color: #30be4d;
}
.lusterniaBlurb3 {
    color: #8a30be;
}
.starmournBlurb3 {
    color: #4682B4;
}


.settingsDialog #reflexEditorRightPanel .MuiCard-root {
    border-radius: 10px;
    background-color: black;
}

.settingsDialog #reflexEditorRightPanel .actionsHeader, .settingsDialog #reflexEditorRightPanel .actionsAdd {
    background-color: #313131;
    width: 175px;
}

.settingsDialog #reflexEditorRightPanel .actionsContent, .settingsDialog #reflexEditorRightPanel .actionsAddContent {
    display: flex;
    padding-top: 12px !important;
    padding-bottom: 12px !important;
}

.settingsDialog #reflexEditorRightPanel .actionsAddNew {
    /*padding-top: 8px !important;
    padding-bottom: 8px !important;*/
}

.settingsDialog #reflexEditorRightPanel .actionsContent > .growAction {
    flex-grow: 1;
    margin-right: 10px;
}

.settingsDialog #reflexEditorRightPanel .actionsContent > .fixedAction {
    margin-right: 10px;
}


.settingsDialog #reflexEditorRightPanel .MuiCardContent-root {
    border-radius: 10px;
    background-color: black;
    padding: 12px;
}

.settingsDialog #reflexEditorRightPanel .MuiCardContent-root:last-child {
    padding-bottom: 12px;
}

#password-dialog .MuiTextField-root {
    width: 100%;
}

/** DROPZONE */
.dropzone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2;
    border-radius: 2;
    border-color: #777777;
    border-style: dashed;
    background-color: #505050;
    color: #bdbdbd;
    outline: none;
}

#map_overhead_content {
    word-wrap: break-word;
    white-space: pre;
    font-size: 1em;
    line-height: 1.15em;
    text-align: center;
}

.textwindow_content {
    margin: 10px;
    word-wrap: break-word;
    white-space: pre-wrap;
    font-size: 1em;
    line-height: 1.15em;
    overflow-y: scroll;
    height: 100%;
}

.textwindow_content > div {
    min-height: 17px;
}

.contentwindow_content {
    padding: 10px;
    word-wrap: break-word;
    white-space: pre-wrap;
    font-size: 1em;
    line-height: 1.15em;
    overflow-y: auto;
    overflow-x: auto;
    height: 100%;
}

#map_main {
    background-color: #000000;
}

.map_marker {
    position: absolute;
    margin: 0;
    padding: 0;
}


/* avatar dialog */

img.avatar_choose_one {
    display: block;
    width: 64px;
    height: 64px;
    margin: 3px;
    float: left;
    cursor: pointer;
}


/** login */

.login_dialog {
    font-family: 'Montserrat';
}

.login_dialog .login_main {
    display: flex;
    flex-direction: column;
    padding: 20px;
}

.login_dialog .login_main > div {
    text-align: center;
    padding-top: 15px;
}


/** character selector */

.characters_dialog {
    font-family: 'Montserrat';
}

.characters_dialog .top_buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 10px 4px 3px 4px;
}

.characters_dialog .top_buttons > button {
    flex: 1;
}

.characters_dialog .account_name {
    font-size: 11px;
    padding-right: 10px;
    text-transform: none;
}

.character_list .character_list_games {
    flex: 1;
    display: flex;
    flex-direction: column;

}
.character_list .character_list_left {
    background-color: rgba(0,0,0,1);
    overflow: scroll;
}

.character_list .character_list_entries {
    margin: 10px auto;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.3);
    padding: 10px;
    width: calc(100% - 500px);
    margin-left: 300px;
}

.character_list .character_list_gameinfo {
    min-width: 250px;
    width: 25%;
    max-width: 400px;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-right: 5px;
    margin-bottom: 5px;
    align-items: center;
}

.character_list .character_list_gameinfo_inner {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-right: 5px;
    margin-bottom: 5px;
    align-items: center;
}

.character_list .character_list_gameinfo_name {
    font-family: 'CinzelDecorative';
    font-size: 36px;
    font-weight: bold;
    padding-bottom: 5px;
}

.character_list .character_list_right {
    flex-grow: 1;
    width: 100%;
    padding-left: 0px;
    max-height: 100%;
    background-color: rgba(0,0,0,0.4);
    padding-right: 0px;
    position: relative;
    padding-left: 0px;
}

.character_list .character_list_right h4 {
    font-size: 1.6em;
    padding: 15px 0px;
    margin: 0px 0px 25px 0px;
    text-transform: uppercase;
    color: #babcff;
    font-weight: normal;
    text-align: center;
    background-color: rgba(0,0,0,0.5);
}

.character_list .character_list_right .character_list_header {
    display: flex;
    flex-direction: row;
}
.character_list .character_list_right .character_list_title {
    font-weight: bold;
    font-size: 1.2em;
    flex: 1;
}

.character_list .character_list_right .character_entry {
    width: auto;
    background-color: #f8f8f8;
    color: black;
    padding: 15px;
    margin: 5px 0px;
    border-radius: 10px;
}

.character_list .character_list_right .character_entry .MuiTypography-root {
    color: black;
}

.character_list .character_list_right .character_entry.character_selected {
    background-color: rgba(120, 120, 120, 0.8);
}

.character_list {
    display: flex;
    flex-direction: row;
    height: 100%;
    flex: 1;
    overflow-y: scroll;
}

.character_list .character_delete, .character_list .character_settings, .character_list .character_rename, .gameSelector .game_rename, .gameSelector .game_delete {
    vertical-align: middle;
    display: inline-block;
    cursor: pointer;
    margin: 4px;
    padding: 10px;
    color: black;
    background-color: #ddd;
    border-radius: 10px;
    
}

.character_list .character_delete, .character_list .character_settings, .character_list .character_rename {
    font-size: 1.6em;
}

.gameSelector .game_rename, .gameSelector .game_delete {
    font-size: 1.2em;
}

.character_list .MuiButton-label, .gameSelector {
    font-size: 1.2em;
}

.character_list .character_list_empty > div {
    text-align: center;
}

.characters_dialog .account_info hr {
    margin-left: 5%;
    margin-right: 5%;
    color: #888;
}

.characters_dialog .MuiButton-root.greenButton {
    border-radius: 10px;
    text-transform: none;
    background-color: #0a757d;
    border-color: #0a757d;
    font-weight: bold;
    min-width: 100px;
}


.characters_dialog .account_screen {
    display: grid;
    width: 600px;
    gap: 10px;
    grid-template-columns : 1fr 1fr;
    margin: auto;
}

.characters_dialog .account_screen > div > * {
    width: 100%;
}

.characters_dialog .account_screen .account_caption {
    text-align: right;
    padding-top: 15px;
}


/** game selector */

.gameSelector {
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(0,0,0,1);
    padding: 12px;
    min-width: 80px;
    display: flex;
    flex-direction: column;
    z-index: 1;
}

.gameSelector .gamesel_entries {
    overflow-y: auto;
    flex: 1;
}

.gameSelector .gamesel_expanded {
    display: none !important;
    margin-bottom: 0px;
}

.gameSelector .gamesel_bottom {
    margin-left: 7px;
    margin-top: 8px;
}

.gameSelector .gamesel_add {
    padding-bottom: 3px;
    padding-top: 3px;
    cursor: pointer;
}

.gameSelector .gamesel_bottom_text {
    margin-left: 10px;
}

.gameSelector {
    width: 58px;
    transition: width 0.3s;
}

.gameSelector:hover {
    width: 400px;
}

.gameSelector:hover .gamesel_expanded {
    display: inline-flex !important;
    justify-content: center;
}

.gameSelector .gamesel_folded {
    display: block;
}

.gameSelector:hover .gamesel_folded {
    display: none;
}

.gameSelector:hover .gamesel_add.gamesel_expanded {
    font-family: 'Verdana';
}

.gamesel_name {
    font-family: 'CinzelDecorative';
    font-weight: 400;
    font-size: 30px;
    padding: 6px 15px 0px 15px;
    line-height: 28px;
    color: #aaa;
}

.gamesel_subtitle {
    font-size: 14px;
    /*font-style: italic;*/
    color: #aaa;
    padding: 0px 15px 2px 15px;
    white-space: nowrap;
}

.gamesel_active_info {
    background-color: rgba(66,66,66,1);
    padding: 2px 15px 2px 15px;
    border-radius: 0px 0px 10px 10px;
}

.gamesel_blurb {
    font-style: italic;
    padding-left: 30px;
    color: white;
}

.gamesel_header {
    font-size: 22px;
    text-transform: uppercase;
    color: #707070;
    margin-bottom: 5px;
    text-align: center;
    height: 40px;
    white-space: nowrap;
    overflow: hidden;
}

.gamesel_entry {
    margin: 0px 0px;
    cursor: pointer;
    height: 60px;
}

.gameSelector:hover .gamesel_entry {
    border-bottom: 1px solid #ccc;
}


.gamesel_entry:not(.current) img {
    filter: grayscale(1);
}

.gamesel_entry.current .gamesel_name, .gamesel_entry:hover .gamesel_name {
    color: white;
}

.gamesel_entry:not(.current):hover, .gamesel_add:hover {
    background-color: rgba(44,44,44,0.6);
}

.gamesel_entry:hover img {
    filter: none;
}

.characters_bottom {
    position: absolute;
    right: 2px;
    bottom: 2px;
    font-size: 0.8em;
    color: #aaa;
}

/** settings */

.settingsDialog {
    background-color: #222 !important;
}

.settingsDialog .MuiButton-root {
    border-color: #707070;
    border-style: solid;
}

.settingsIcon {
    display: inline-block;
    cursor: pointer;
    padding-top: 3px;
    padding-bottom: 3px;
    font-size:1.6em;
}
.settingsIcon:hover {
    color: #80ff80;
}

.react-codemirror2 {
    width: 100%;
}


/** rc-tree overrides */

.rc-tree-node-selected {
    background-color: #222 !important;
    box-shadow: 0 0 0 1px #444 !important;
}

.timestamp {
    color: #555;
}

.timestamp:hover {
    color: #888;
}


/** MOBILE STYLES */

@media (hover: none) { 
    .character_list .character_list_entries {
        width: calc(100% - 160px);
        margin-left: 120px;
    }
    .MuiButtonBase-root {
        min-width: 96px;
        line-height: 2;
        font-size: 1.4em;
    }
    .MuiDialogTitle-root {
        font-size: 1.9em;
    }
}
